<template>
    <v-card flat>

        <v-row style="width:100%">
            <v-col class="d-flex justify-center">
                <v-card flat>
                    <v-card-text>
                        <v-layout align-center justify-space-between row fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                <v-btn @click="noScale"
                                    color="info"
                                    v-on="{ ...tooltip }">
                                    <v-icon>mdi-fullscreen{{ fullBtnTxt === 'big' ? '' : '-exit' }}</v-icon> {{ fullBtnTxt }}
                                </v-btn>
                                </template>
                                <span>{{ $t('check.show_original_size') }}</span>
                            </v-tooltip>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col class="d-flex justify-center">
                <v-card flat>
                    <v-card-text>
                        <v-layout align-center justify-space-between row fill-height>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                <v-btn @click="setRef"
                                    color="info"
                                    v-on="{ ...tooltip }">
                                    <v-icon>mdi-image-multiple</v-icon>
                                </v-btn>
                                </template>
                                <span>{{ $t('check.set_reference') }}</span>
                            </v-tooltip>

                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col class="d-flex justify-center">
                <v-card flat>
                    <v-card-text>
                        <v-layout align-center justify-space-between row fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-dialog
                                        v-model="showPreview"
                                        width="80%"
                                        max-width="1500"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="info"
                                                v-bind="attrs"
                                                v-on="{...on, ...tooltip}">
                                                <v-icon>mdi-play</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <Preview :systemId="id"
                                                :siteviewId="siteviewId"
                                                :lastImageAt="lastImageUpdate" />
                                        </v-card>
                                    </v-dialog>

                                </template>
                                <span>{{ $t('check.play_week') }}</span>
                            </v-tooltip>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col class="d-flex justify-center">
                <v-card flat>
                    <v-card-text>
                        <v-layout align-center justify-space-between row fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                <v-btn @click="skipCheck"
                                    color="info"
                                    :disabled="data.nextSystem === null"
                                    v-on="{ ...tooltip }">
                                    <v-icon>mdi-skip-next</v-icon>
                                </v-btn>
                                </template>
                                <span>{{ $t('check.skip') }}</span>
                            </v-tooltip>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-divider class="mt-2"></v-divider>

        <v-row style="width:100%" v-if="data.totalCount > 1">
            <v-col>
                <v-card flat>
                    <v-card-text class="py-1">
                        {{ $t('unchecked') }}: <strong>{{ data.totalCount }}</strong>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-divider v-if="data.totalCount > 1"></v-divider>

        <v-card-text v-if="data.id" class="pb-2">
            {{ $t('check.choose_condition') }}
            <strong>
                <router-link :to="'/system/'+data.id">
                    <a>{{ $t('camera.name') }} {{ data.camNo }} ({{ data.computerName }})</a>
                </router-link>
            </strong> {{ $t('of_project') }} <strong>
                <router-link :to="'/project/'+data.projectId">
                    <a>{{ data.projectName }}</a>
                </router-link>
            </strong>.
        </v-card-text>

        <v-card-text class="py-2">
            {{ $t('check.autofocus') }}: <strong>{{ autofocusInfoString }}</strong>
            <span v-if="data.make || data.model"> ({{ data.make }} {{ data.model }})</span>
        </v-card-text>

        <v-card-text class="py-2">
            {{ $t('check.reference_date') }}: <strong>{{ refDateHR }}</strong>
        </v-card-text>

        <v-card-text class="py-2">
            {{ $t('check.origin') }}: <strong>{{ server ? server : 'FTP' }}</strong>
        </v-card-text>

        <v-card-text class="pt-2">
            {{ $t('check.last_image_at') }}: <strong>{{ lastImageUpdate ? getDate(lastImageUpdate): '---' }}</strong>
        </v-card-text>

        <v-card-text v-if="checkDisabled">
            <b>+++ {{ noCheckMessage }} +++</b>
        </v-card-text>

        <v-card-text v-else class="pb-1">
            <v-row >
                <v-col cols="3" class="py-0">
                    <div class="v-input v-input--selection-controls mt-0">
                        <label class="v-label theme--light">{{ $t('check.in_focus') }}</label>
                    </div>
                </v-col>
                <v-col class="py-0">
                    <v-radio-group v-model="inFocus"
                                :disabled="checkDisabled"
                                   row
                                   class="mt-0">
                        <v-radio :label="$t('no')" :value="0"></v-radio>
                        <v-radio :label="$t('yes')" :value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3" class="py-0">
                    <div class="v-input v-input--selection-controls mt-0">
                        <label class="v-label theme--light">{{ $t('check.is_clean') }}</label>
                    </div>
                </v-col>
                <v-col class="py-0">
                    <v-radio-group v-model="isClean"
                                :disabled="checkDisabled"
                                   row
                                   class="mt-0">
                        <v-radio :label="$t('no')" :value="0"></v-radio>
                        <v-radio :label="$t('yes')" :value="2"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3" class="py-0">
                    <div class="v-input v-input--selection-controls mt-0">
                        <label class="v-label theme--light">{{ $t('check.free_sight') }}</label>
                    </div>
                </v-col>
                <v-col class="py-0">
                    <v-radio-group v-model="freeSight"
                                :disabled="checkDisabled"
                                   row
                                   class="mt-0">
                        <v-radio :label="$t('no')" :value="0"></v-radio>
                        <v-radio :label="$t('yes')" :value="4"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3" class="py-0">
                    <div class="v-input v-input--selection-controls mt-0">
                        <label class="v-label theme--light">{{ $t('check.in_position') }}</label>
                    </div>
                </v-col>
                <v-col class="py-0">
                    <v-radio-group v-model="inPosition"
                                :disabled="checkDisabled"
                                   row
                                   class="mt-0">
                        <v-radio :label="$t('no')" :value="0"></v-radio>
                        <v-radio :label="$t('yes')" :value="8"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text class="pb-0 pt-0" v-if="!checkDisabled">
            <v-row>
                <v-col cols="3">
                    <div class="v-input v-input--selection-controls">
                        <label class="v-label theme--light">{{ $t('note') }}</label>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="11">
                <v-textarea solo class="note-area"
                            v-model="note"></v-textarea>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text class="py-0" v-if="!checkDisabled && lastNotes.length > 0">
            <v-list subheader>
                <v-subheader style="height: unset;">{{ $t('previous_notes') }}</v-subheader>

                <div v-for="(prevNote, index) in lastNotes"
                    :key="index">
                <v-list-item v-if="prevNote.text">

                    <v-list-item-content>
                        <v-row class="pre-note-row" :data-raw="prevNote.raw">
                            <v-col>
                                {{ prevNote.date }}
                            </v-col>
                            <v-col>
                                {{ prevNote.text }}
                            </v-col>
                            <v-col>
                                {{ prevNote.user }}
                            </v-col>
                        </v-row>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn icon
                            @click="removeNote(index)">
                            <v-icon color="grey lighten-1">mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                </div>
            </v-list>
        </v-card-text>

        <v-card-actions v-if="!checkDisabled">
            <v-row>
                <v-col>
                    <v-btn color="warning"
                        :disabled="!valid"
                        @click="validate">
                        <v-icon>mdi-send</v-icon>
                        {{ $t('send') }}
                    </v-btn>
                </v-col>
                <v-col>
                    <v-card flat>
                        <v-card-text v-if="data.nextSystem"
                            class="py-1">
                            {{ $t('next') }}: <strong>{{ data.nextSystem.projectName }} {{ data.nextSystem.camNo }}</strong>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-actions>

        <v-card-text class="mt-2" v-if="!checkDisabled">
            <v-row>
                <v-col cols="3">
                    {{ $t('check.last_check_at') }}:
                </v-col>
                <v-col>
                    <a :href="getImageUrl(lastCheckDate)" target="_blank">{{ lastCheckDate ? getDate(lastCheckDate, true) : '---' }}</a>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3">
                    {{ $t('check.checked_by') }}:
                </v-col>
                <v-col>
                    <a :href="`mailto:${lastCheckUserMail}`">{{ lastCheckUser }}</a>
                </v-col>
            </v-row>
        </v-card-text>

        <v-expansion-panels class="pt-5" flat hover>
            <v-expansion-panel v-if="history.length > 0"
                    class="my-1">

                <v-expansion-panel-header>
                    <v-layout align-center
                            justify-start>

                        <v-icon>mdi-history</v-icon>
                        <label class="v-label theme--light ml-1 title">{{ $t('history') }}</label>

                    </v-layout>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-card v-for="(item,i) in history"
                            :key="i"
                            flat>
                        <v-card-text v-if="item.user">
                            {{ $t('by') }}
                            <b><a :href="`mailto:${item.user.email}`">{{ item.user.displayname }}</a></b> ( <a :href="getImageUrl(item.date)" target="_blank">{{ getDate(item.date, true) }}</a> )
                        </v-card-text>
                        <v-card-text v-else>
                            {{ $t('by') }} {{ $t('unknown') }}
                        </v-card-text>

                        <v-row>
                            <v-col>
                                <v-card-text>{{ $t('check.in_focus') }} <v-icon :color="item.inFocus > 0 ? 'success' : 'error'">{{ item.inFocus > 0 ? 'mdi-check' : 'mdi-close' }}</v-icon></v-card-text>
                                <v-card-text>{{ $t('check.is_clean') }} <v-icon :color="item.isClean > 0 ? 'success' : 'error'">{{ item.isClean > 0 ? 'mdi-check' : 'mdi-close' }}</v-icon></v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-text>{{ $t('check.free_sight') }} <v-icon :color="item.freeSight > 0 ? 'success' : 'error'">{{ item.freeSight > 0 ? 'mdi-check' : 'mdi-close' }}</v-icon></v-card-text>
                                <v-card-text>{{ $t('check.in_position') }} <v-icon :color="item.inPosition > 0 ? 'success' : 'error'">{{ item.inPosition > 0 ? 'mdi-check' : 'mdi-close' }}</v-icon></v-card-text>
                            </v-col>
                        </v-row>

                        <v-card-text v-if="item.note">
                            <div v-if="getNotesTree(i).length > 0">
                                <v-card-title>{{ $t('note') }}</v-card-title>
                                <v-card-subtitle>{{ getNotesTree(i)[0].text }}</v-card-subtitle>
                            </div>

                            <v-list subheader v-if="prevNotesExist(i)">
                                <v-subheader>{{ $t('previous_notes') }}</v-subheader>

                                <div v-for="(prevNote, index) in getNotesTree(i)"
                                    :key="index">
                                    <v-list-item
                                        v-if="(index > 0 && prevNote.text)">

                                        <v-list-item-content>
                                            <v-row class="pre-note-row" :data-raw="prevNote.raw">
                                                <v-col>
                                                    {{ prevNote.date }}
                                                </v-col>
                                                <v-col>
                                                    {{ prevNote.text }}
                                                </v-col>
                                                <v-col>
                                                    {{ prevNote.user }}
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                            </v-list>
                        </v-card-text>

                        <v-card-text v-else>
                            {{ $t('check.no_note') }}
                        </v-card-text>

                        <hr v-if="i<history.length-1" />
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </v-card>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment-timezone'
import axios from 'axios'
import { rest } from '../_helpers'
import { inputToRecord } from '../_helpers/misc'
import { getToken } from '../_helpers/auth'

const Preview = () => import(
    /* webpackChunkName: "comp-check" */ '@/components/CheckPreview.vue'
)


export default {
    props: {
        id: Number,
        siteviewId: Number,
        data: {
            default() {
                return {}
            },
            type: Object,
        },
        lastCheckDate: String,
        lastCheckUser: String,
        lastCheckUserMail: String,
        lastImageUpdate: String,
        server: String,
        refDatetime: Number,
    },

    data() {
        return {
            showSpinner: true,
            rules: {
                required: [
                    v => !!v || 'Field is required',
                ],
            },
            inFocus: null,
            isClean: null,
            freeSight: null,
            inPosition: null,
            note: '',
            history: [],
            fullBtnTxt: 'big',
            refDate: this.refDatetime,
            showPreview: false,
            currentCheckDate: null,
            lastNotes: [],
        }
    },

    components: {
        Preview,
    },

    computed: {
        ...mapState({
            account: 'account',
        }),

        autofocus() {
            let focus = false
            if (this.data.make) {
                focus = ['walimex', 'laowa', 'samyang'].indexOf(this.data.make.toLowerCase()) >= 0
            }
            return focus
        },

        autofocusInfoString() {
            let afString = 'YES'
            if (!this.autofocus) {
                if (this.data.make) {
                    afString = 'NO'
                } else {
                    afString = 'No FTP-Index information'
                }
            }
            return afString
        },

        refDateHR() {
            return moment(this.refDate).format('YYYY:MM:DD HH:mm:ss')
        },

        valid() {
            return this.inFocus != null && this.isClean != null && this.freeSight != null && this.inPosition != null
        },

        checkDisabled() {
            return false // this.lastCheckDate && moment(this.lastCheckDate) >= moment().startOf('day')
        },

        noCheckMessage() {
            return `Check was already done at ${moment(this.lastCheckDate).format()} by ${this.data.displayname}`
        },
    },

    methods: {
        ...mapActions({
            setConfirmDialogData: 'page/setConfirmDialogData',
        }),

        getDate(date, local) {
            let dateStr = moment(date).format('YYYY-MM-DD HH:mm')
            if (local) {
                dateStr = moment.utc(date).format('YYYY-MM-DD HH:mm')
            }

            return dateStr
        },

        getImageUrl(datetime) {
            const imageName = moment.utc(datetime).format('YYYY_MMDD_HHmm00')
            return `${process.env.VUE_APP_REST_DOMAIN}/static/images/archive/${this.id}/${imageName}_full.jpg?token=${getToken()}`
        },

        setRef() {
            this.setConfirmDialogData({
                title: this.$t('check.set_reference'),
                text: `${this.$t('ref_question')} ${this.$t('camera.name')} ${this.data.camNo} (${this.data.computerName}) ${this.$t('of_project')} ${this.data.projectName}`,
                func: () => rest.setRef(this.id)
                    .then(result => {
                        if (result && result.success) {
                            this.refDate = Date.now()
                        }
                    }),
            })
        },

        noScale() {
            this.fullBtnTxt = this.fullBtnTxt === 'big' ? 'small' : 'big'
            this.$emit('no-scale', this.fullBtnTxt !== 'big')
        },

        skipCheck() {
            this.$emit('edit-item-saved', [null, 'skip'])
        },

        prevNotesExist(historyIndex) {
            let rows
            if (this.history.length) {
                rows = this.history[historyIndex].note.split(';;;').length > 1
            }

            return rows
        },

        getNotesTree(historyIndex) {
            const notesTree = []
            if (this.history.length) {
                const rows = this.history[historyIndex].note

                if (rows) {
                    const noteSplit = rows.split(';;;')
                    noteSplit.forEach(row => {
                        const cols = row.split(' | ')
                        cols.push(row)
                        notesTree.push({
                            date: cols[0],
                            text: cols[1],
                            user: cols[2],
                            raw: row,
                        })
                    })
                }
            }

            return notesTree
        },

        removeNote(val) {
            this.lastNotes.splice(val, 1)
        },

        getCurrentImage() {
            const url = `${process.env.VUE_APP_REST_DOMAIN}/archive/${this.id}?token=${getToken()}`
            const body = {
                offset: 0,
                siteview: this.siteviewId ? this.siteviewId : null,
            }

            return axios.post(url, body)
                .then(res => {
                    const { data } = res
                    const currentCheckDate = moment(data.createdAt)
                    if (currentCheckDate.isValid()) {
                        this.currentCheckDate = currentCheckDate
                    } else {
                        // TODO what is the wrong data.createdAt; get last image
                        this.currentCheckDate = moment()
                    }
                })
                .catch(e => {
                    console.log(url, e.message)
                })
        },

        validate() {
            const status = this.inFocus + this.isClean + this.freeSight + this.inPosition

            const user = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY))

            const checkDate = this.currentCheckDate.format('YYYY-MM-DD HH:mm:ss')
            const mergedNote = []
            if (this.note) {
                this.lastNotes.unshift({
                    date: checkDate,
                    text: this.note,
                    user: user.user.username,
                    raw: `${checkDate} | ${this.note} | ${user.user.username}`,
                })
                mergedNote.push(`${checkDate} | ${this.note} | ${user.user.username}`)
            }

            const preNotes = document.getElementsByClassName('pre-note-row')
            Array.from(preNotes).forEach(preNote => {
                mergedNote.push(preNote.dataset.raw)
            })

            const data = {
                status,
                note: mergedNote.join(';;;'),
                systemId: this.id,
                userId: user.user.id,
                date: this.currentCheckDate.format('YYYY-MM-DD HH:mm:ss'),
            }

            const record = inputToRecord(data)

            rest.setItem('check', record)
                .then(response => {
                    if (!response.error
                        && response.affectedRows.length === 1
                        && response.insertId >= 0) {
                        record.inFocus = this.inFocus
                        record.isClean = this.isClean
                        record.freeSight = this.freeSight
                        record.inPosition = this.inPosition
                        record.user = { displayname: this.account.user.user.displayname }
                        record.type = 'check'

                        // history to task
                        let desc = `<a href=https://index.mktimelapse.com/system/${this.id}>${this.$t('go_to')} ${this.data.projectName} ${this.data.camNo}</a><br/><br/>`
                        if (!this.inFocus) {
                            desc += 'Not in Focus<br/>'
                        }
                        if (!this.isClean) {
                            desc += 'Not Clean<br/>'
                        }
                        if (!this.freeSight) {
                            desc += 'No Free Sight<br/>'
                        }
                        if (!this.inPosition) {
                            desc += 'Not in Position<br/>'
                        }
                        if (this.note) {
                            desc += `<br/>Note<br/>${this.note}`
                        }

                        if (this.history.length) {
                            desc += '<br/><br/>History<br/><table>'

                            this.history.forEach(his => {
                                desc += `<tr><td class="history-date" style="border:none">${his.date}</td><td class="history-info" style="border:none">`

                                let stat = ''
                                if (!his.inFocus) {
                                    stat += 'Not in Focus<br/>'
                                }
                                if (!his.isClean) {
                                    stat += 'Not Clean<br/>'
                                }
                                if (!his.freeSight) {
                                    stat += 'No Free Sight<br/>'
                                }
                                if (!his.inPosition) {
                                    stat += 'Not in Position<br/>'
                                }
                                if (!stat) {
                                    desc += 'No Problems'
                                }
                                desc += '</td></tr>'
                            })

                            desc += '</table>'
                        }

                        this.history = [record].concat(this.history)


                        if (!this.inFocus || !this.isClean || !this.freeSight || !this.inPosition) {
                            const task = {
                                title: `Maintenance for ${this.data.projectName} Camera ${this.data.camNo}`,
                                description: desc,
                                importance: 'High',
                                responsibles: [JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY)).user.id],
                                dates: {
                                    start: moment().format('YYYY-MM-DD'),
                                    due: moment().add(7, 'days').format('YYYY-MM-DD'),
                                },
                                status: 'Active',
                            }

                            // TODO: set wrike task to REST
                            rest.setTask(task)
                                .then(taskDone => {
                                    console.log(taskDone)
                                })
                        }

                        this.inFocus = null
                        this.isClean = null
                        this.freeSight = null
                        this.inPosition = null
                        this.note = ''

                        this.$emit('edit-item-saved', [record, 'check'])
                    } else {
                        // TODO: need alert
                        console.log(response)
                    }
                })
        },

        getLastCheck(sysId) {
            rest.getCheckHistory(sysId, 10)
                .then(items => {
                    if (items) {
                        this.history = items
                        this.lastNotes = this.getNotesTree(0)
                    }
                })
        },

        renew(systemId) {
            this.getLastCheck(systemId)
            this.getCurrentImage()
        },
    },

    created() {
        if (this.id) {
            this.getLastCheck(this.id)
            this.getCurrentImage()
        }
    },

    watch: {
        id(val) {
            this.renew(val)
        },

        refDatetime(val) {
            this.refDate = val
        },
    },
}
</script>
